import { Module } from "vuex";
import { getters } from "@/store/transactions/getters";
import { actions } from "@/store/transactions/actions";
import { mutations } from "@/store/transactions/mutations";
import { TransactionsState } from "@/store/transactions/types";
import { RootState } from "@/store/types";
import { pagingDefault } from "../utils";

export const state: TransactionsState = {
  transactions: [],
  searchedOrg: [],
  loading: false,
  totalItems: 0,
  pagination: pagingDefault,
};

const namespaced: boolean = true;

export const transactions: Module<TransactionsState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
