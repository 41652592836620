import { MutationTree } from "vuex";
import { TransactionsState } from "@/store/transactions/types";
import { transactions } from ".";

export const mutations: MutationTree<TransactionsState> = {
  setTransactions(state, payload) {
    state.transactions = payload;
    state.loading = false;
  },

  /* Paging */
  paginate(state, payload) {
    state.pagination = payload;
  },
  setTotalItems(state, payload) {
    state.totalItems = payload;
  },

  loading(state) {
    state.loading = true;
  },

  refundSuccess(state) {
    state.loading = false;
  },

  transactionSuccess(state) {
    state.loading = false;
  },

  setSearchOrg(state, payload = null) {
    state.searchedOrg = payload;
    state.loading = false;
  },
};
