import { ActionTree } from "vuex";
import { TransactionsState } from "@/store/transactions/types";
import { RootState } from "@/store/types";
import {
  fetchTransaction,
  refundTransaction,
  cashoutTransaction,
  transactionsFetchParams,
  CashoutBody,
  cashoutTransactionExecute,
  getOrganisationByCode,
} from "@/api/transactions";

export const actions: ActionTree<TransactionsState, RootState> = {
  async getTransactions(
    { commit, state },
    transactObj: transactionsFetchParams
  ) {
    try {
      for (const item in transactObj) {
        if (!transactObj[item]) transactObj[item] = undefined;
      }
      commit("loading");
      const result = await fetchTransaction(state.pagination, transactObj);
      commit("setTransactions", result.data.results);
      commit("setTotalItems", result.data.count);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading");
    }
  },

  async getOrganisationByCode({ commit, dispatch }, code: string) {
    try {
      commit("loading");
      const response = await getOrganisationByCode(code);
      commit("setSearchOrg", response.data);
      return true;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      return false;
    }
  },

  async doRefund({ commit, dispatch }, refundBody) {
    try {
      // @todo: Fix call when available
      /* commit("loading");
			const response = await refundTransaction(refundBody); */

      commit("loading");
      dispatch("notifications/error", "Refund transaction under maintenance", {
        root: true,
      });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading");
    }
  },

  async doCashout({ commit, dispatch }, cashoutBody: CashoutBody) {
    try {
      commit("loading");
      const response = await cashoutTransaction(cashoutBody);
      let regex =
        /(http|ftp|https):\/\/[a-z-.]*(\/resource\/v2\/transactions)(%3F|\?)+/g;
      let location = response.headers.location;
      let params = location.replace(regex, "");
      let paramArr = params.split("&");
      let txnId = null;
      paramArr.forEach((param: any) => {
        let arr = param.split("=");
        if (arr[0] === "transaction_id") {
          txnId = +arr[1];
        }
      });

      if (txnId) {
        const result: any = await cashoutTransactionExecute({
          transactingOrgId: cashoutBody.transactingOrgId,
          txnId: txnId,
        });

        if (result.data[0].statusKey === "failed") {
          dispatch("notifications/error", result.data[0].statusReason, {
            root: true,
          });
        } else {
          dispatch(
            "notifications/success",
            "Cashout transaction was successful and is being deposited",
            {
              root: true,
            }
          );
        }
        commit("transactionSuccess");
      }
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading");
    }
  },

  refresh({ dispatch }) {
    dispatch("getTransactions");
  },

  paginate({ commit }, pagination) {
    commit("paginate", pagination);
  },
};
